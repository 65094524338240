const acceptTerms = document.querySelector("#accept_terms");
const submitBtn = document.querySelector("#submit_form");

const auditedCheckbox = document.querySelector("#audited-pwc");

acceptTerms.addEventListener("change", ({ target }) => {
  submitBtn.disabled = !target.checked;
});

auditedCheckbox.addEventListener("change", ({ target }) => {
  if (target.checked) {
    window.open('https://www.pwc.co.nz/services/pwc-academy/independence-requirements-form.html');
  }
});
